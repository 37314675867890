<template>
  <div class="column" style="background: #F1F1F1;">
    <div class="row" style="width:calc(100% - 10px);margin-top: 10px;margin-left: 10px;">
      <el-input
        v-model="youdaoTitel"
        placeholder="请输入查询的词"
        @keyup.enter.native="onSelectWordEnter"
      />
      <el-button-group style="width:200px;margin-left: 10px;">
        <el-button type="primary" icon="el-icon-tickets" @click="onModelSentence">例句</el-button>
      </el-button-group>
    </div>
    <div style="height:calc(100% - 55px);margin-top: 10px;margin-left: 10px;    ">
      <div v-if="msLogin" style="font-size: 10px;color: #777;margin: 20px 0px;">检索例句中...</div>
      <el-scrollbar v-else style="height: calc(100% - 45px);;width: 100%;">
        <div
          v-for="(item, index) in PageModelSentenceList"
          :key="index"
          class="column ms-row"
          style="cursor:pointer;"
          @click.stop="osMSOpacity(PageModelSentenceIndex*20+ index)"
        >
          <span class="ms-english">
            <span>{{ PageModelSentenceIndex*20+ index+1 }}.</span>
            <div
              v-if="((item.frequency0>0 || item.frequency1>0) && item.frequency2==0 && item.frequency3==0 && item.frequency4==0 && item.frequency5==0)"
              class="frequency"
              style="background: #EEA531;"
            >初级</div>
            <div
              v-if="((item.frequency0>0 || item.frequency1>0 ) && item.frequency2>0 && item.frequency3==0 && item.frequency4==0 && item.frequency5==0)"
              class="frequency"
              style="background: #3D8D2B;"
            >初中级</div>
            <div
              v-if="((item.frequency2>0 || item.frequency3>0) && item.frequency4==0 && item.frequency5==0)"
              class="frequency"
              style="background: #2FA2D2;"
            >中级</div>
            <div
              v-if="((item.frequency2>0 || item.frequency3>0 ) && item.frequency4>0 && item.frequency5==0)"
              class="frequency"
              style="background: #2E63C3;"
            >中高级</div>
            <div
              v-if="((item.frequency4>0 || item.frequency5>0))"
              class="frequency"
              style="background: #9D24B3;"
            >高级</div>
            <span
              style="max-width: 580px;-webkit-touch-callout: text;-webkit-user-select: text; -khtml-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text;margin-left: 5px;"
              v-html="item.english"
            ></span>
            <div
              class="play"
              style="background: #5961cd;margin-left: 20px;"
              @click.stop="onMsPlay(1,item.englishText)"
            >
              <img src="../assets/images/home/播放按钮.png" />
            </div>
            <div
              class="play"
              style="background: #B62B1A;margin-left: 10px;"
              @click.stop="onMsPlay(2,item)"
            >
              <img src="../assets/images/home/播放按钮.png" />
            </div>
            <i v-if="item.isMsLoading" class="el-icon-loading" style="margin-left:5px;"></i>
          </span>
          <span class="ms-chinese">
            <div v-if="item.chinese=='' || item.chinese== null" style=" display: flex;align-items: center;">
              <svg @click.stop="onTranslation(item)" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4077" width="24" height="24"><path d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m100.266667 419.114667h-67.456v31.786667h-105.984v53.973333h105.984v34.688h-116.565334v53.930667h116.565334v79.018666h67.413333V678.826667h127.146667v-53.930667h-127.146667v-34.688h116.608v-53.973333h-116.608v-31.786667z m-233.642667-62.634667H273.152v62.634667h40.96v160.853333c0 17.109333-3.669333 29.866667-10.965333 38.314667l-2.090667 2.176 34.688 47.232c21.504-22.485333 50.773333-47.701333 87.68-75.648-3.84-26.325333-5.973333-51.84-6.272-76.586667a368.128 368.128 0 0 1-31.317333 29.226667l-7.210667 5.973333v-194.133333z m351.146667-139.690666h-298.666667v53.504h54.186667l-37.290667 20.224c19.584 24.874667 39.808 45.738667 60.672 62.592a587.52 587.52 0 0 1-105.002667 24.106666c13.141333 19.626667 23.594667 38.528 31.317334 56.832 50.56-8.533333 96.469333-22.186667 137.770666-40.96 36.437333 16.725333 87.168 29.568 152.192 38.570667l5.418667-13.354667c5.717333-13.909333 12.373333-29.525333 20.138667-46.890666-40.149333-2.56-76.202667-8.661333-108.16-18.304a403.712 403.712 0 0 0 80.512-75.093334l6.912-8.704v-52.48z m-85.76 53.504a279.04 279.04 0 0 1-68.608 53.205333A324.010667 324.010667 0 0 1 516.394667 362.666667l-6.997334-6.997334h134.656zM334.848 283.306667l-48.64 38.058666c23.424 29.226667 45.738667 58.112 66.944 86.698667 18.304-14.421333 36.608-28.416 54.912-41.898667a2357.845333 2357.845333 0 0 0-65.706667-74.666666l-7.509333-8.192z" fill="#000000" p-id="4078" /></svg>
              <span v-html="item.tempHtml"></span>
            </div>
            <span v-else v-html="item.chinese"></span>
          </span>
          <span class="ms-provenance">来自:{{ item.grouping }}({{ item.name }})</span>
        </div>
        <div
          v-if="PageModelSentenceList.length==0"
          style="font-size: 10px;color: #777;margin: 50px 0px;display: flex; justify-content: center;align-items: center;"
        >
          <img src="../assets/images/sentence/无内容.png" style="width:16px;" />
          <label style="margin-left:5px">无内容</label>
        </div>
      </el-scrollbar>
      <div
        v-if="ModelSentenceList.length>0"
        class="row"
        style="position:absolute;bottom: 20px;right: 40px;font-size: 12px;color: #c61200;z-index: 9999;"
      >
        <label
          v-if="ModelSentenceList.length>=1"
          :style="{'font-weight':PageModelSentenceIndex===0 ? 'bold' : ''}"
          style="cursor:pointer;"
          @click="onModelSentenceListPage(1)"
        >&nbsp;第1页&nbsp;</label>
        <label
          v-if="ModelSentenceList.length>=20"
          :style="{'font-weight':PageModelSentenceIndex===1 ? 'bold' : ''}"
          style="cursor:pointer;"
          @click="onModelSentenceListPage(2)"
        >&nbsp;第2页&nbsp;</label>
        <label
          v-if="ModelSentenceList.length>=40"
          :style="{'font-weight':PageModelSentenceIndex===2 ? 'bold' : ''}"
          style="cursor:pointer;"
          @click="onModelSentenceListPage(3)"
        >&nbsp;第3页&nbsp;</label>
        <label
          v-if="ModelSentenceList.length>=60"
          :style="{'font-weight':PageModelSentenceIndex===3 ? 'bold' : ''}"
          style="cursor:pointer;"
          @click="onModelSentenceListPage(4)"
        >&nbsp;第4页&nbsp;</label>
        <label
          v-if="ModelSentenceList.length>=80"
          :style="{'font-weight':PageModelSentenceIndex===4 ? 'bold' : ''}"
          style="cursor:pointer;"
          @click="onModelSentenceListPage(5)"
        >&nbsp;第5页&nbsp;</label>
      </div>
    </div>
  </div>
</template>
<script>
import { modelSentence } from '@/apis/subtitle'
import { youDaoTranslate } from '@/apis/sys'
import router from '@/router'
export default {
  name: 'Sentence',
  components: {},
  props: {},
  data() {
    return {
      audio: {}, // 音频播放
      youdaoTitel: '',
      msLogin: false, // 例句检索中
      isModelSentence: false, // 滑词例句
      ModelSentenceList: [], // 例句总集合
      PageModelSentenceIndex: 0, // 例句分页索引
      PageModelSentenceList: [], // 例句分页集合
      ModelSentenceWordList: [] // 例句搜索记录
    }
  },
  computed: {},
  watch: {},
  created() {
    var jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction
    if (!jurisdiction.menus['eq']) {
      router.push({
        name: 'Noaccess'
      })
      return
    }
  },
  mounted() {
    this.audio = new Audio()
  },
  methods: {
    // 查词
    onSelectWordEnter() {
      this.onModelSentence()
    },
    // 搜索字幕例句
    onModelSentence() {
      var that = this
      this.isModelSentence = true
      this.msLogin = true
      // 例句关键词搜索记录
      if (
        that.ModelSentenceWordList.length === 0 ||
        that.ModelSentenceWordList[that.ModelSentenceWordList.length - 1] !==
          this.youdaoTitel.trim()
      ) {
        that.ModelSentenceWordList.push(this.youdaoTitel.trim())
      }
      var example = localStorage.getItem('example')
      var zhOpacity = 0
      var enOpacity = 0
      if (example) {
        switch (example) {
          case '1':
            zhOpacity = 1
            enOpacity = 1
            break
          case '2':
            zhOpacity = 0
            enOpacity = 1
            break
          case '3':
            zhOpacity = 1
            enOpacity = 0
            break
          case '4':
            zhOpacity = 0
            enOpacity = 0
            break
        }
      }
      // 查询条件/排序
      var level = localStorage.getItem('level')
      var sort = localStorage.getItem('sort')
      var where = '1=1'
      if (level) {
        level = JSON.parse(level)
        if (level.length < 5 && level.length !== 0) {
          level.forEach(x => {
            if (where === '1=1') {
              where = `${where} and (`
            } else {
              where = `${where} or`
            }
            switch (x) {
              case '初级':
                where = `${where} ((t1.Frequency0>0 or t1.Frequency1>0) and t1.Frequency2=0 and t1.Frequency3=0 and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '初中级':
                where = `${where} ((t1.Frequency0>0 or t1.Frequency1>0 ) and t1.Frequency2>0 and t1.Frequency3=0 and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '中级':
                where = `${where} ((t1.Frequency2>0 or t1.Frequency3>0) and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '中高级':
                where = `${where} ((t1.Frequency2>0 or t1.Frequency3>0 ) and t1.Frequency4>0 and t1.Frequency5=0)`
                break
              case '高级':
                where = `${where} ((t1.Frequency4>0 or t1.Frequency5>0))`
                break
            }
          })
          where = `${where} )`
        } else {
          where = ''
        }
      }
      if (sort) {
        sort = JSON.parse(sort)
        var orderBy = ''
        if (sort.length > 0) {
          if (where === '') {
            where = '1=1'
          }
          sort.forEach(x => {
            switch (x) {
              case '学习时间':
                orderBy = `${orderBy} t5.LearningTime DESC,`
                break
              case '例句长度':
                orderBy = `${orderBy} if(ISNULL(t1.Length),10000,t1.Length),`
                // where = `${where} and t1.Length>0`
                break
              case '复杂程度':
                orderBy = `${orderBy} if(ISNULL(t1.MaxLevel),7,t1.MaxLevel),`
                // where = `${where} and t1.MinLevel>0`
                break
            }
          })
          orderBy = orderBy.substring(0, orderBy.lastIndexOf(','))
        }
      }
      // 查询例句
      modelSentence({
        word: this.youdaoTitel.trim(),
        orderBy: orderBy,
        where: where
      }).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          element.englishText = element.english
          that.youdaoTitel = that.youdaoTitel.trim()
          var symbol = that.youdaoTitel.substring(that.youdaoTitel.length - 1)
          if (
            symbol === '.' ||
            symbol === ',' ||
            symbol === '?' ||
            symbol === '!' ||
            symbol === '！' ||
            symbol === '？'
          ) {
            that.youdaoTitel = that.youdaoTitel.substring(
              0,
              that.youdaoTitel.length - 1
            )
          }
          var reg = new RegExp(`\\b${that.youdaoTitel}\\b`, 'i', 'g')
          element.english = element.english.replace(
            reg,
            `<span style="color: #00a803;opacity: 0;" id="msword${index}">${that.youdaoTitel}</span>`
          )
          element.english = `<span style="opacity: ${enOpacity};" id="msEnglish${index}">${element.english}</span>`
          if (element.chinese === null || element.chinese === '') {
            element.chinese = ''
            element.tempHtml = `<span style="opacity: ${zhOpacity};" id="msChinese${index}">无中文字幕，可选择机译</span>`
          } else {
            element.chinese = `<span style="opacity: ${zhOpacity};" id="msChinese${index}">${element.chinese}</span>`
          }
          element.isMsLoading = false
        }
        this.ModelSentenceList = res.data
        this.PageModelSentenceIndex = 0
        if (this.ModelSentenceList.length > 0) {
          if (this.ModelSentenceList.length < 20) {
            this.PageModelSentenceList = this.ModelSentenceList.slice(
              0,
              this.ModelSentenceList.length
            )
          } else {
            this.PageModelSentenceList = this.ModelSentenceList.slice(0, 20)
          }
        } else {
          this.PageModelSentenceList = []
        }
        this.msLogin = false
      })
    },
    // 例句翻页
    onModelSentenceListPage(page) {
      this.PageModelSentenceIndex = page - 1
      switch (page) {
        case 1:
          this.PageModelSentenceList = this.ModelSentenceList.slice(0, 20)
          break
        case 2:
          this.PageModelSentenceList = this.ModelSentenceList.slice(20, 40)
          break
        case 3:
          this.PageModelSentenceList = this.ModelSentenceList.slice(40, 60)
          break
        case 4:
          this.PageModelSentenceList = this.ModelSentenceList.slice(60, 80)
          break
        case 5:
          this.PageModelSentenceList = this.ModelSentenceList.slice(80, 100)
          break
      }
    },
    // 合成例句发音
    onMsPlay(type, obj) {
      // console.log(obj)
      var that = this
      if (type === 1) {
        this.audio.src = `http://dict.youdao.com/speech?audio=${obj}`
        this.audio.play()
      } else if (type === 2) {
        console.log(obj)
        obj.isMsLoading = true
        this.audio.src = obj.audio
        this.audio.currentTime = obj.startMillisecond / 1000
        var audioTimeupdate = function(event) {
          if (that.audio.currentTime >= obj.endMillisecond / 1000) {
            that.audio.pause()
            that.audio.removeEventListener('timeupdate', audioTimeupdate)
            that.audio.removeEventListener('canplay', canplay)
          }
        }
        var canplay = function() {
          obj.isMsLoading = false
        }
        this.audio.play()
        this.audio.addEventListener('timeupdate', audioTimeupdate)
        this.audio.addEventListener('canplay', canplay)
      }
    },
    // 例句隐藏
    osMSOpacity(index) {
      if (document.getElementById('msEnglish' + index).style.opacity !== '1') {
        document.getElementById('msEnglish' + index).style.opacity = 1
      } else if (
        document.getElementById('msword' + index).style.opacity !== '1'
      ) {
        document.getElementById('msword' + index).style.opacity = 1
      } else if (
        document.getElementById('msChinese' + index).style.opacity !== '1'
      ) {
        document.getElementById('msChinese' + index).style.opacity = 1
      } else {
        var example = localStorage.getItem('example')
        if (example && example === '1') {
          document.getElementById('msword' + index).style.opacity = 0
        } else if (example && example === '2') {
          document.getElementById('msChinese' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
        } else if (example && example === '3') {
          document.getElementById('msEnglish' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
        } else if (!example || example === '4') {
          document.getElementById('msEnglish' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
          document.getElementById('msChinese' + index).style.opacity = 0
        }
      }
    },
    // 机译
    onTranslation(item) {
      youDaoTranslate({ word: item.englishText, isApi: true }).then(res => {
        item.tempHtml = item.tempHtml.replace('无中文字幕，可选择机译', res.data.translation[0])
        item.tempHtml = item.tempHtml.replace('style="opacity: 0;"', 'style="opacity: 1;"')
      })
    }
  }
}
</script>
  <style lang='less' scoped>
//变量定义
@primary-clor: #6a6aee;
@padding-horizontal: 20px;
@padding-vertical: 10px;
@list-operation-height: 50px;
@tabs-top-height: 40px;

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.ms-row {
  margin: 10px 0px;
  padding: 5px;
  border-width: 0 0 1px 0;
  border-color: #f9f6f6;
  border-style: solid;
  .ms-english {
    font-weight: bold;
    display: flex;
    align-items: center;
    // justify-content: center;
    color: #333333;
    font-size: 14px;
    margin-bottom: 5px;
    .frequency {
      padding: 0px 8px;
      color: white;
      border-radius: 2px;
      font-size: 12px;
      font-weight: normal;
      margin-left: 4px;
    }
    .play {
      width: 28px;
      height: 28px;
      border-radius: 5px;
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .ms-chinese {
    color: #666666;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .ms-provenance {
    color: #959595;
    font-size: 12px;
  }
}
</style>
